const config = {
  api: {
    baseUrl: 'https://pilots.mobilizelabs.org/coachtofit/v1.0',
    client: 'ctf-dashboard'
  },
  messaging: {
    baseUrl: 'https://msg.apps.ucla.edu/v1.0/apps/coachtofit',
    username: 'coachtofit',
    password: 'TKQmTRcPdRxITz1Wko6f1mMMk6sZU0sC'
  },
  campaigns: {
    setup: {
      campaignUrn: 'urn:va:coachtofit:surveysaccess:cohort2:setUp',
      surveyId: 'setUp'
    },
    weight: {
      campaignUrn: 'urn:va:coachtofit:surveysaccess:cohort2:weightSync',
      surveyId: 'weightSync'
    },
    steps: {
      campaignUrn: 'urn:va:coachtofit:surveysaccess:cohort2:steps',
      surveyId: 'steps'
    },
    goals: {
      campaignUrn: 'urn:va:coachtofit:surveysaccess:cohort2:trackGoals',
      surveyId: 'trackGoalsSurvey'
    },
    chapterSubmit: {
      campaignUrn: 'urn:va:coachtofit:surveysaccess:cohort2:trackChapterSubmit',
      surveyId: 'trackChapterSubmit'
    },
    chapterRelease: {
      campaignUrn: 'urn:va:coachtofit:surveysaccess:cohort2:trackChaptersRelease',
      surveyId: 'trackChaptersRelease'
    },
    coach: {
      campaignUrn: 'urn:va:coachtofit:surveys:cohort2:coachSurvey4',
      initialSurveyId: 'initial',
      hubSurveyId: 'topicSelection',
      nextCallSurveyId: 'nextCall',
      topicSurveyIds: [
        'maintenance',
        'motivation',
        'physical',
        'nutrition',
        'nextCall'
      ]
    }
  },
  organization: 'Coach to Fit',
  emailDomain: 'va.gov',
  studyIdPrefix: 'VAPITT'
}

module.exports = config
