import config from '../config'
import { request } from '../helpers/request'

// ACTION CREATORS //

function messagesSetData(messages) {
  return {
    type: 'MESSAGES_SET_DATA',
    messages
  }
}

function messagesAdd(message) {
  return {
    type: 'MESSAGES_ADD',
    message
  }
}

// API METHODS //

function authorizationHeader() {
  const credentials = config.messaging.username + ':' + config.messaging.password
  return 'Basic ' + Buffer.from(credentials).toString('base64')
}

export function messagesFetchData(studyId) {
  let url = config.messaging.baseUrl + '/messages/external/'
  url += config.studyIdPrefix + studyId

  const params = {
    Authorization: authorizationHeader()
  }

  return (dispatch, getState) => {
    function onSuccess(json) {
      dispatch(messagesSetData(json))
    }

    request(url, 'GET', params, dispatch, getState, onSuccess)
  }
}

export function messagesSend(studyId, body) {
  const url = config.messaging.baseUrl + '/messages/external'

  return (dispatch, getState) => {
    function onSuccess(json) {
      dispatch(messagesAdd({
        body,
        sent: Date.now()
      }))
    }

    const params = {
      Authorization: authorizationHeader(),
      externalUniqueId: config.studyIdPrefix + studyId,
      body
    }

    request(url, 'POST', params, dispatch, getState, onSuccess)
  }
}
